export default [
  {
      path: '/invoice/:id',
      name: 'invoice-details',
      component: () => import('@/views/pages/outside-system/invoices/Invoice.vue'),
      props: true,
      meta: {
        layout: 'full',
      },
    },
]