export default [
    {
        path: '/',
        name: 'login',
        component: () => import('@/views/pages/authentication/Login.vue'),
        meta: {
          layout: 'full',
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/pages/authentication/Logout.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('@/views/pages/authentication/Register.vue'),
        meta: {
          layout: 'full',
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
        meta: {
          layout: 'full',
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/views/pages/authentication/ResetPassword.vue'),
        meta: {
          layout: 'full',
          redirectIfLoggedIn: true,
        },
      },
      {
        path: '/verify-account-codes/:id',
        name: 'verify-account-codes',
        component: () => import('@/views/pages/authentication/VerifyAccount.vue'),
        props: true,
        meta: {
          layout: 'full',
          redirectIfLoggedIn: true,
        },
      },
  ]
  