export default [
  {
    path: '/contacts/all-contacts',
    name: 'client-all-contacts',
    component: () => import('@/views/pages/contacts/Contacts.vue'),
    meta: {
      pageTitle: 'All Contacts',
      breadcrumb: [
        {
          text: 'Contacts',
          active: true,
        },
        {
          text: 'All Contacts',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/contacts/invalid-contacts',
    name: 'client-invalid-contacts',
    component: () => import('@/views/pages/contacts/InvalidContacts.vue'),
    meta: {
      pageTitle: 'Invalid Contacts',
      breadcrumb: [
        {
          text: 'Contacts',
          active: true,
        },
        {
          text: 'Invalid Contacts',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/contacts/groups/:groupId/:name',
    name: 'client-group-contacts',
    component: () => import('@/views/pages/contacts/Contacts.vue'),
    props: true,
    meta: {
      pageTitle: 'Group Contacts',
      breadcrumb: [
        {
          text: 'Contacts',
          active: true,
        },
        {
          text: 'Groups',
          to: "/contacts/groups",
        },
        {
          text: 'Group Contacts',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/contacts/groups',
    name: 'client-groups',
    component: () => import('@/views/pages/contacts/Groups.vue'),
    meta: {
      pageTitle: 'Groups',
      breadcrumb: [
        {
          text: 'Contacts',
          active: true,
        },
        {
          text: 'Groups',
          active: true,
        },
      ],
      requiresAuth: true,
    },
  },
]
  