export default [
  {
    path: '/voice/compose-voice',
    name: 'compose-voice',
    component: () => import('@/views/pages/voice/ComposeVoice.vue'),
    meta: {
      pageTitle: 'Compose Voice',
      breadcrumb: [
        {
          text: 'Voice',
          active: true,
        },
        {
          text: 'Compose Voice',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/voice/outbox',
    name: 'outbox-voice',
    component: () => import('@/views/pages/voice/Outbox.vue'),
    meta: {
      pageTitle: 'Outbox',
      breadcrumb: [
        {
          text: 'Voice',
          active: true,
        },
        {
          text: 'Outbox',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  },
  {
    path: '/voice/scheduled',
    name: 'scheduled-voices',
    component: () => import('@/views/pages/voice/ScheduledVoices.vue'),
    meta: {
      pageTitle: 'Scheduled Voice Campaign',
      breadcrumb: [
        {
          text: 'Voice',
          active: true,
        },
        {
          text: 'Scheduled Voice Campaign',
          active: true,
        }
      ],
      requiresAuth: true,
    },
  },
]