export default [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/pages/dashboard/Dashboard.vue'),
        meta: {
          pageTitle: 'Dashboard',
          breadcrumb: [
            {
              text: 'Dashboard',
              active: true,
            },
          ],
          requiresAuth: true,
        },
      },
]