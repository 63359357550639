export default [
    {
      path: '/buy-credits',
      name: 'buy-credits',
      component: () => import('@/views/pages/billings/BuyCredits.vue'),
      meta: {
        pageTitle: 'Buy Credits',
        breadcrumb: [
          {
            text: 'Buy Credits',
            active: true,
          }
        ],
        requiresAuth: true,
        adminonly: true,
      },
    },
    {
      path: '/buy-credits/mpesa',
      name: 'mpesa',
      component: () => import('@/views/pages/billings/Mpesa.vue'),
      meta: {
        pageTitle: 'M-Pesa Payment Service',
        breadcrumb: [
          {
            text: 'Buy Credits',
            to: "/buy-credits",
          },
          {
            text: 'M-Pesa Payment Service',
            active: true,
          },
        ],
        requiresAuth: true,
        adminonly: true,
      },
    },
    {
      path: '/buy-credits/bank-eft',
      name: 'bank-eft',
      component: () => import('@/views/pages/billings/BankEFT.vue'),
      meta: {
        pageTitle: 'Bank EFT Payment Service',
        breadcrumb: [
          {
            text: 'Buy Credits',
            to: "/buy-credits",
          },
          {
            text: 'Bank EFT Payment Service',
            active: true,
          },
        ],
        requiresAuth: true,
        adminonly: true,
      },
    },
    {
      path: '/transaction-history',
      name: 'transaction-history',
      component: () => import('@/views/pages/billings/TransactionHistory.vue'),
      meta: {
        pageTitle: 'Transaction History',
        breadcrumb: [
          {
            text: 'Transaction History',
            active: true,
          }
        ],
        requiresAuth: true,
        adminonly: true,
      },
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: () => import('@/views/pages/billings/Invoices.vue'),
      meta: {
        pageTitle: 'Invoices',
        breadcrumb: [
          {
            text: 'Invoices',
            active: true,
          }
        ],
        requiresAuth: true,
        adminonly: true,
      },
    },
]