export default [
  {
    path: '/team/users',
    name: 'team-users',
    component: () => import('@/views/pages/team/Users.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Team',
          active: true,
        },
        {
          text: 'Users',
          active: true,
        },
      ],
      
      requiresAuth: true,
      adminonly: true,
    },
  },
  {
    path: '/team/requests',
    name: 'team-requests',
    component: () => import('@/views/pages/team/Requests.vue'),
    meta: {
      pageTitle: 'Requests',
      breadcrumb: [
        {
          text: 'Team',
          active: true,
        },
        {
          text: 'Requests',
          active: true,
        },
      ],
      
      requiresAuth: true,
      adminonly: true,
    },
  },
  {
    path: '/team/accounts',
    name: 'team-accounts',
    component: () => import('@/views/pages/team/Accounts.vue'),
    meta: {
      pageTitle: 'Accounts',
      breadcrumb: [
        {
          text: 'Team',
          active: true,
        },
        {
          text: 'Accounts',
          active: true,
        },
      ],
      
      requiresAuth: true,
    },
  },
];