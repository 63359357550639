export default [
    {
      path: '/sms/compose-message',
      name: 'compose-message',
      component: () => import('@/views/pages/messages/ComposeMessage.vue'),
      meta: {
        pageTitle: 'Compose Message',
        breadcrumb: [
          {
            text: 'Message',
            active: true,
          },
          {
            text: 'Compose Message',
            active: true,
          }
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/sms/outbox',
      name: 'outbox',
      component: () => import('@/views/pages/messages/Outbox.vue'),
      meta: {
        pageTitle: 'Outbox',
        breadcrumb: [
          {
            text: 'Message',
            active: true,
          },
          {
            text: 'Outbox',
            active: true,
          }
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/sms/scheduled',
      name: 'scheduled-messages',
      component: () => import('@/views/pages/messages/ScheduledMessages.vue'),
      meta: {
        pageTitle: 'Scheduled SMS Campaign',
        breadcrumb: [
          {
            text: 'Message',
            active: true,
          },
          {
            text: 'Scheduled SMS Campaign',
            active: true,
          }
        ],
        requiresAuth: true,
      },
    },
]