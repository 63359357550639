import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import auth from './routes/auth'
import { isUserLoggedIn, getHomeRouteForLoggedInUser, getUserAccount } from '@/auth/utils'

import dashboard from './routes/dashboard'
import contact from './routes/contact'
import senderId from './routes/sender-id'
import billing from './routes/billing'
import message from './routes/message'
import user from './routes/user'
import voice from './routes/voice'
import team from './routes/team'
import developer from './routes/developer'
import outside from './routes/outside'
import notFound from './routes/not-found'




Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...auth,
    ...dashboard,
    ...billing,
    ...senderId,
    ...contact,
    ...message,
    ...user,
    ...voice,
    ...team,
    ...developer,
    ...outside,
    ...notFound,
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  const userAccount = getUserAccount()

  if(to.meta.requiresAuth && !isLoggedIn){
    return next({ name: 'login' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser("dummy-user"))
  }
  
  // redirect to not found page if user access unauthorzied routes
  if(to.meta.adminonly && isLoggedIn && userAccount.Role != "Admin"){
    return next({ name: 'error-404' })
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router