export default [
    {
      path: '/developer-api',
      name: 'developer-api',
      component: () => import('@/views/pages/developer-api/DeveloperAPI.vue'),
      meta: {
        pageTitle: 'Developer API',
        breadcrumb: [
          {
            text: 'Developer API',
            active: true,
          }
        ],
        requiresAuth: true,
        adminonly: true,
      },
    },
  ]