export default [
  {
    path: '/sender-id',
    name: 'sender-id',
    component: () => import('@/views/pages/sender-id/SenderId.vue'),
    meta: {
      pageTitle: 'Sender Id',
      breadcrumb: [
        {
          text: 'Sender Id',
          active: true,
        }
      ],
      requiresAuth: true,
      adminonly: true,
    },
  },
]
    